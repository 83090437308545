// 用户合同
import request from "./request.js";


// /web/contract/list web-合同签订表-列表 post  /web/user/contract
// /web/contract/edit web-合同签订表-编辑 post
// /web/contract/save web-合同签订表-新增 post
// /web/contract/delete web-合同签订表-删除 post
// /web/contract/find web-合同签订表-详细 post


export function con_list(data) {
    return request({
        url: "/web/user/contract",
        method: "post",
        data:data,
    });
}
export function con_edit(data) {
    return request({
        url: "/web/contract/edit",
        method: "post",
        data: data,
    });
}
export function con_save(data) {
    return request({
        url: "/web/contract/save",
        method: "post",
        data: data,
    });
}
export function con_delete(data) {
    return request({
        url: "/web/contract/delete",
        method: "post",
        data: data,
    });
}

// 合同详细信息图片
export function con_find(data) {
    return request({
        url: "/web/contract/find",
        method: "post",
        data: data,
    });
}

// 用户详情 /web/consumer/find
export function user_find(data) {
    return request({
        url: "/web/consumer/find",
        method: "post",
        data: data,
    });
}

// 用户修改信息 /web/consumer/edit
export function user_edit(data) {
    return request({
        url: "/web/consumer/edit",
        method: "post",
        data: data,
    });
}


export function user_edit_password(data) {
    return request({
        url: "/web/consumer/edit_password",
        method: "post",
        data: data,
    });
}



// 用户查看结算管理  /web/statement_order/list      /web/user/statement/manage
export function ord_info(data) {
    return request({
        url: "/web/user/statement/manage",
        method: "post",
        data: data,
    });
}

// 用户查看结算单详情  /web/statement_order/find
export function ord_find(data) {
    return request({
        url: "/web/statement_order/find",
        method: "post",
        data: data,
    });
}


// 用户修改结算单   /web/statement_order/edit
export function ord_edit(data) {
    return request({
        url: "/web/statement_order/edit",
        method: "post",
        data: data,
    });
}


// 用户发票抬头
// 列表
export function rec_list(data) {
    return request({
        url: "/web/receipt_header/list",
        method: "post",
        data: data,
    });
}

// 新增     /web/receipt_header/save
export function rec_add(data) {
    return request({
        url: "/web/receipt_header/save",
        method: "post",
        data: data,
    });
}

// 详情 /web/receipt_header/find
export function rec_find(data) {
    return request({
        url: "/web/receipt_header/find",
        method: "post",
        data: data,
    });
}
// 发票头修改   /web/receipt_header/edit
export function rec_edit(data) {
    return request({
        url: "/web/receipt_header/edit",
        method: "post",
        data: data,
    });
}
// 发票头删除 /web/receipt_header/delete
export function rec_delete(data) {
    return request({
        url: "/web/receipt_header/delete",
        method: "post",
        data: data,
    });
}


// 订单查询     /web/order_info/list
export function order_info(data) {
    return request({
        url: "/web/user/order",
        method: "post",
        data: data,
    });
}

// 用户发票新增  /web/consumer_receipt/save
export function rec_save(data) {
    return request({
        url: "/web/consumer_receipt/save",
        method: "post",
        data: data,
    });
}

// 用户收藏  /web/user/collection
export function col_list(data) {
    return request({
        url: "/web/user/collection",
        method: "post",
        data: data,
    });
}

// 用户收藏删除
export function col_del(data) {
    return request({
        url: "/web/consumer_collect/delete",
        method: "post",
        data: data,
    });
}


// 用户订单确定     /web/statement/confirm
export function con_firm(data) {
    return request({
        url: "/web/statement/confirm",
        method: "post",
        data: data,
    });
}

// 系统发送信息
export function mes_add(data) {
    return request({
        url: "/web/message/save",
        method: "post",
        data: data,
    });
}