<template>
  <div class="list-manage settlement-manage">
    <section class="list-manage-table">
      <div class="table-title"><span class="title">结算管理</span><span
          class="order-number">总计：<small>¥</small><big>{{ totalMany }}</big></span></div>
      <div class="month-dropdown">
        <div :class="{ btns: true, btns_sel: sOp == 1 }" @click="sOrp(1)" v-if="user.is_procet == 1">我提交的</div>
        <div :class="{ btns: true, btns_sel: sOp == 2 }" @click="sOrp(2)" v-if="user.is_supplier == 1">我接收的</div>
        <!-- 我提交的 -->
        <el-dropdown placement="bottom" @command="(command) => { choses(command) }"
          style="margin-top: 6px;border-left:1px solid #E8E8E8;padding: 0 30px;width:160px" v-if="sOp == 1">
          <span class="el-dropdown-link">
            {{ choices }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="0">全部</el-dropdown-item>
            <el-dropdown-item command="1">待审核</el-dropdown-item>
            <el-dropdown-item command="2">审核失败</el-dropdown-item>
            <el-dropdown-item command="3">已审核</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 我接受的 -->
        <el-dropdown placement="bottom" @command="(command) => { choses1(command) }"
          style="margin-top: 6px;border-left:1px solid #E8E8E8;padding: 0 30px;width:160px" v-else>
          <span class="el-dropdown-link">
            {{ choices1 }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">待处理</el-dropdown-item>
            <el-dropdown-item command="2">全部</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 日期 -->
        <el-dropdown placement="bottom" @command="(command) => { chose(command) }"
          style="margin-top: 6px;border-left:1px solid #E8E8E8;padding-left:30px">
          <span class="el-dropdown-link">
            {{ choice }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">本月</el-dropdown-item>
            <el-dropdown-item command="2">上月</el-dropdown-item>
            <el-dropdown-item command="3">本年</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="thead">
        <span class="th">结算编号</span>
        <span class="th">创建时间</span>
        <span class="th">订单号</span>
        <span class="th">状态</span>
        <span class="th">结算金额</span>
        <span class="th">操作</span>
      </div>
      <template v-if="list.length != 0">
        <div class="tbody" v-loading="loading">
          <div class="list-item" v-for="(item, index) in list" :key="index">
            <div class="list-item-main">
              <div class="td">{{ item.id }}</div>
              <div class="td">{{ item.create_time }}</div>
              <div class="td">{{ item.order_id }}</div>
              <div class="td">{{ item.order_state_name }}</div>
              <!-- <div class="td">{{item.order_type == 1 ? '中期结算' : '最终结算'}}</div> -->
              <div class="td f-orange">¥{{ item.reality_rental_fee }}</div>
              <div class="td">
                <span class="btn" @click="Details(item.id)">查看</span>
                <span class="btn" v-if="item.order_state == 3 && flag == 1"
                  @click="toSettle(item.id, item.order_state, item.order_type, item.order_id)">重新申请</span>
                <span class="btn" v-if="item.order_state == 0 && flag == 1"
                  @click="toSettle(item.id, item.order_state, item.order_type, item.order_id)">重新申请</span>
                <span class="btn" v-if="item.order_state == 1 && flag == 1"
                  @click="toSettle(item.id, item.order_state, item.order_type, item.order_id)">取消结算</span>
                <!-- <span class="btn">下载结算表</span> -->
              </div>
            </div>
          </div>
        </div>
      </template>
      <div v-else-if="list.length === 0 && !loading"
        style="text-align: center;height: 40px;line-height: 40px;font-size: 18px;">暂无数据</div>
    </section>
    <div class="pagination">
      <el-pagination background layout="total,prev, pager, next,jumper" :page-size="page.pageSize"
        :current-page="page.pageNumber" :total="total.totalRow" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
    <!-- 查看详情 -->
    <el-dialog title="结算单详情" :visible.sync="addBillDialogVisible" width="800px" class="add-bill-dialog"
      :lock-scroll='true'>
      <div class="add-bill-form">
        <div class="form-item" style="width:700px;font-weight: 700;font-size:20px;margin-bottom: 5px;">结算单基本信息</div>
        <div class="form-item">
          <div class="form-label">结算单编号：</div>
          <el-input v-model="find.id" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">申请时间：</div>
          <el-input v-model="find.create_time" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">实际使用时间：</div>
          <el-input v-model="find.rental_time_start" placeholder="暂无…" style="width: 100px;" readonly></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">&nbsp;</div>
          <div style="height:40px;line-height:40px">至</div>
        </div>
        <div class="form-item">
          <div class="form-label">&nbsp;</div>
          <el-input v-model="find.rental_time_end" placeholder="暂无…" style="width: 100px;margin-left:10px" readonly>
          </el-input>
        </div>
        <div class="form-item">
          <div class="form-label">&nbsp;</div>
          <div style="height:40px;line-height:40px">共 <span style="color:#ff992b;">{{ find.rental_time_day }}</span> 天
          </div>
        </div>
        <div class="form-item">
          <div class="form-label">结算金额</div>
          <el-input v-model="find.reality_rental_fee" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div style="width:700px;font-weight: 800;font-size:20px;margin-bottom: 5px;">设备信息</div>
        <!-- <div class="form-item">
          <div class="form-label">设备id</div>
          <el-input  placeholder="暂无…" style="width: 340px;" readonly ></el-input>
        </div> -->
        <div class="form-item">
          <div class="form-label">租赁费</div>
          <el-input type="text" v-model="find.reality_rental_fee" placeholder="暂无…" style="width: 340px;" readonly>
          </el-input>
        </div>
        <!-- <div class="form-item">
          <div class="form-label">设备标题</div>
          <el-input v-model="find.name" placeholder="暂无…" style="width: 340px;" readonly ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">租赁方式</div>
          <el-input placeholder="暂无…" style="width: 340px;" readonly ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">分类</div>
          <el-input placeholder="暂无…" style="width: 340px;" readonly ></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">数量</div>
          <el-input placeholder="暂无…" style="width: 340px;" readonly ></el-input>
        </div> -->
        <div style="width:700px;font-weight: 800;font-size:20px;margin-bottom: 5px;">供应商信息</div>
        <div class="form-item">
          <div class="form-label">id</div>
          <el-input v-model="find.seller_consumer_id" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">名称</div>
          <el-input v-model="seller_consumer_name" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div style="width:700px;font-weight: 800;font-size:20px;margin-bottom: 5px;">项目方信息</div>
        <div class="form-item">
          <div class="form-label">id</div>
          <el-input v-model="find.purchaser_consumer_id" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div class="form-item">
          <div class="form-label">名称</div>
          <el-input v-model="purchaser_consumer_name" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <!-- <div style="width:700px;font-weight: 800;font-size:20px;margin-bottom: 5px;">发票信息</div>
        <div class="form-item">
          <div class="form-label">发票状态</div>
          <el-input v-model="page.pageNumber" placeholder="暂无…" style="width: 340px;" readonly ></el-input>
        </div> -->
        <div style="width:700px;font-weight: 800;font-size:20px;margin-bottom: 5px;">订单信息</div>
        <div class="form-item">
          <div class="form-label">订单编号</div>
          <el-input v-model="find.order_id" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
        <div style="width:700px;font-weight: 800;font-size:20px;margin-bottom: 5px;">审核信息</div>
        <div class="form-item">
          <div class="form-label">审核状态</div>
          <el-input v-model="find.order_state_name" placeholder="暂无…" style="width: 340px;" readonly></el-input>
        </div>
      </div>
      <div class="footer-btn" v-if="flag == 0 && find.order_state == 1">
        <span class="base-btn" @click="edit(find.id, 2, find.order_type, find.order_id)">确认</span>
        <span class="base-btn" @click="edit(find.id, 3, find.order_type, find.order_id)">回绝</span>
      </div>
      <div class="footer-btn" v-else>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { ord_info, ord_find, user_find, con_firm } from '@/api/contract'
import { getuser } from "@/api/user"
// import { mes_add } from '../../api/contract';
// import settle from "@/components/settle"
export default {
  // components: {
  //   settle
  // },
  data() {
    return {
      loading: true,
      sOp: 1,
      user: {},
      totalMany: '',
      page: {
        pageNumber: 1,
        pageSize: 10,
      },
      total: {
        totalPage: null,
        totalRow: null
      },
      seller_consumer_name: null,
      purchaser_consumer_name: null,
      list: [],
      listall: [],
      addBillDialogVisible: false,
      find: {
        "contract_id": 1,
        "seller_consumer_id": 1,
        "latitude": null,
        "rental_time_start": "2022-07-11 00:00:00",
        "reality_rental_time_start": null,
        "remark": "暂无相关信息",
        "reality_rental_time_end": null,
        "reality_rental_fee": 20000.00,
        "create_by": "001",
        "update_time": "2022-11-08 08:48:08",
        "rental_time_day": 365,
        "rental_time_end": "2030-07-11 00:00:00",
        "id": 1,
        "parent_order_id": 1,
        "update_by": "001",
        "order_type": 1,
        "longitude": null,
        "estimate_rental_fee": 2000.00,
        "del_flag": "1",
        "address": "福州",
        "create_time": "2022-10-27 11:21:26",
        "purchaser_consumer_id": 2,
        "name": null,
        "fail_reason": null,
        "order_state": 1,
        "order_id": 0
      },
      choice: '本月',
      choices: '全部',
      choices1: '待审核',
      choiceNum: 1,
      choicesNum: 1,
      choicesNum1: 1,
      // A: 全部 B: 本月 C:上月
      listA: [],
      listB: [],
      listC: [],
      state: [0, 1, 2, 3, 6],
      stime: null,
      etime: null,
      flag: 1,
    };
  },
  created() {
    this.user = getuser()
    if (this.user.is_procet == 1) { this.sOp = 1; this.choses() }
    else if (this.user.is_supplier == 1) { this.sOp = 2; this.flag = 0; this.choses1() }
    this.chose()
    this.getinfo()
  },
  methods: {
    getinfo() {
      ord_info({ inputs: { flag: this.flag, order_state: this.state, start_date: this.stime, end_date: this.etime }, pagination: this.page }).then(res => {
        // ord_info({inputs:{flag:this.flag,order_state:this.state},pagination:this.page}).then(res => {
        this.listA = res.data.statement_order.list
        this.total.totalRow = res.data.statement_order.totalRow
        this.totalMany = res.data.estimate_rental_fee
        for (let i = 0; i < this.listA.length; i++) {
          if (this.listA[i].order_state != null) {
            this.listA[i].order_state_name = this.listA[i].order_state == 0 ? '已取消' : this.listA[i].order_state == 1 ? '待审核' : this.listA[i].order_state == 2 ? '已审核' : this.listA[i].order_state == 3 ? '审核失败' : '已完结'
          }
          if (this.listA[i].rental_time_start) {
            let time = this.listA[i].rental_time_start.split(" ")
            this.listA[i].rental_time_start = time[0]
          }
          if (this.listA[i].rental_time_end) {
            let time = this.listA[i].rental_time_end.split(" ")
            this.listA[i].rental_time_end = time[0]
          }
          if (this.listA[i].create_time) {
            let time = this.listA[i].create_time.split(" ")
            this.listA[i].create_time = time[0]
          }
        }
        this.list = this.listA
        this.loading = false
      })
    },
    handleCurrentChange(val) {
      this.page.pageNumber = val
      this.getinfo()
    },
    // 查看详情
    Details(idd) {
      ord_find({ id: idd }).then(res => {
        this.find = res.data
        this.find.order_state_name = this.find.order_state == 0 ? '已取消' : this.find.order_state == 1 ? '待审核' : this.find.order_state == 2 ? '已审核' : this.find.order_state == 3 ? '审核失败' : this.find.order_state == 4 ? '结算中' : this.find.order_state == 5 ? '结算失败' : '已完结'
        // 项目方
        user_find({ id: this.find.purchaser_consumer_id }).then(res => {
          this.purchaser_consumer_name = res.data.consumer_name
        })
        // 供应商
        user_find({ id: this.find.seller_consumer_id }).then(res => {
          this.seller_consumer_name = res.data.consumer_name
        })
      })

      this.addBillDialogVisible = true
    },
    // 切换年份
    chose(c = 1) {
      this.choiceNum = c
      this.choice = c == 1 ? '本月' : c == 2 ? '上月' : '本年'
      let date = new Date()
      let year = date.getFullYear()
      let month = date.getMonth() + 1
      if (month < 10) {
        month = '0' + month
      }
      if (this.choiceNum == 1) {
        // 本月
        let obj = year + "-" + month + "-01 00:00:00"
        let days = new Date(year, month, 0).getDate()
        if (days < 10) {
          days = '0' + days
        }
        let end = year + "-" + month + "-" + days + " 23:59:59"
        this.stime = obj
        this.etime = end
      } else if (this.choiceNum == 2) {
        // 上一月
        let month1 = month - 1
        if (month1 == 0) {
          year = year - 1
          month1 = 12
        }
        if (month1 < 10) {
          month1 = '0' + month1
        }
        let obj = year + "-" + month1 + "-01" + " 00:00:00"
        let days = new Date(year, month1, 0).getDate()

        let end = year + "-" + month1 + "-" + days + " 23:59:59"
        this.stime = obj
        this.etime = end
      } else {
        // 本年
        let year1 = year - 1
        let obj = year1 + "-" + month + "-" + "01" + " 00:00:00"
        let days = new Date(year, month, 0).getDate()
        if (days < 10) {
          days = '0' + days
        }
        let end = year + "-" + month + "-" + "days" + " 23:59:59"
        this.stime = obj
        this.etime = end
      }
      this.page.pageNumber = 1
      this.getinfo()
    },
    // 切换状态 我提交的
    choses(s = 0) {
      this.page.pageNumber = 1
      this.choicesNum = s
      this.choices = s == 0 ? '全部' : s == 1 ? '待审核' : s == 2 ? '审核失败' : '已审核'
      if (this.choicesNum == 0) {
        this.state = [0, 1, 2, 3, 6]
      } else if (this.choicesNum == 1) {
        this.state = [1]
      } else if (this.choicesNum == 2) {
        this.state = [3]
      } else {
        this.state = [2, 3]
      }
      this.flag = 1
      this.getinfo()
    },
    // 切换状态   我审核的
    choses1(s = 1) {
      this.page.pageNumber = 1
      this.choicesNum1 = s
      this.choices1 = s == 1 ? '待处理' : '全部'
      if (this.choicesNum1 == 1) {
        this.state = [1]
      } else {
        this.state = [0, 1, 2, 3, 6]
      }
      this.flag = 0
      this.getinfo()
    },
    toSettle(id, state, type, o_id) {
      if (state == 1) {
        this.$confirm('是否确定取消结算', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.edit(id, 0, type, o_id)
        }).catch(() => {
          this.$message.info('已取消删除')
        });
      } else if (state == 3) {
        this.$router.push({ path: '/personalCenter/orderManage', query: { id: this.user.id } });
      }
    },
    sOrp(i) {
      this.sOp = i
      if (i == 1) {
        this.flag = 1
        this.choses()
      } else {
        this.flag = 0
        this.state = [1]
        this.choses1()
      }
      this.getinfo()
    },
    // 结算
    edit(i, j, k, l) {
      // i:结算单id     j:结算单要变得状态      k:结算单类型  l:订单id  m:订单状态{6:中期结算完成    7:中期结算失败   10：已完成  9：完结结算失败   }
      let m,a
      if(j == 2) { a = `供应商${this.user.consumer_name}同意了你本次结算单号为${i}的结算申请` }else {  a = `供应商${this.user.consumer_name}回绝了你本次结算单号为${i}的结算申请` }
      console.log('信息：',a);
      if (j == 2) {
        // 成功
        if (k == 2) { j = 6; m = 10 }
        // 如果是完结结算单
        else { m = 6 }
        // 如果不是
      } else {
        // 失败
        if (k == 2) { m = 9 }
        // 如果是完结结算单
        else { m = 7 }
      }
      con_firm({ inputs: { id: i, order_state: m, order_id: l, statement_order_state: j } }).then(res => {
        if (res.status == 200) {
          this.$message.success('成功')
          this.addBillDialogVisible = false
          // if (j != 0) {
          //   mes_add({ inputs: { receive_consumer_id: this.find.purchaser_consumer_id, title: "结算申请结果通知", type: 3, message_state: 2, content:a, send_consumer_id: this.user.id } })
          //   .then(r => {
          //     if(r.status == 200) { console.log('成功！'); }
          //   })
          //   .catch((err) => {
          //     this.$message.error(err)
          //   })
          // }
        } else {
          this.$message({
            message: '失败',
            type: 'warning'
          });
          this.addBillDialogVisible = false
        }
         setTimeout(() => { location.reload(); window.scroll(0, 0) }, 500)
      })
    }
  }
}
</script>

<style scoped>
.settlement-manage .month-dropdown {
  display: flex;
  position: relative;
  padding-left: 50px;
  margin-bottom: 10px;
}

.btns {
  font-size: 16px;
  color: #383838;
  cursor: pointer;
  transition: 0.3s;
  padding: 6px 16px 9px;
  margin-right: 16px;
  border-bottom: 4px solid #fff;
}

.btns_sel {
  color: #ff992b;
  border-bottom-color: #ff992b;
}

.btns:hover {
  color: #ff992b;
  border-bottom-color: #ff992b;
}

.add-bill-dialog .footer-btn {
  text-align: left;
  padding: 16px 0 6px;
}

.add-bill-dialog .footer-btn .base-btn {
  width: 230px;
  height: 60px;
  border-radius: 50px;
  margin-left: 100px;
}

.spanshow {
  margin: 0 10px 5px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #e2e2e2;
  background-color: #f1f3f5;
  min-width: 66px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: not-allowed;
  transition: 0.3s;

}

.settlement-manage .list-manage-table .thead .th:nth-of-type(3),
.settlement-manage .list-manage-table .tbody .td:nth-of-type(3) {
  width: 100px;
}

.settlement-manage .list-manage-table .thead .th:nth-of-type(4),
.settlement-manage .list-manage-table .tbody .td:nth-of-type(4) {
  width: 160px;
}

.settlement-manage .list-manage-table .thead .th:last-of-type,
.settlement-manage .list-manage-table .tbody .td:last-of-type {
  width: 230px;
}

.list-manage .list-manage-table .btn {
  margin: 0 10px 5px;

}

/deep/ .el-dialog {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  border-radius: 10px;
  height: 80%;
  overflow: hidden;
}

/deep/ .el-dialog__body {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  overflow-y: auto;
  height: 92%;
}
</style>
